<template>
  <div class="min-vh-70 pt-8">
    <h3 class="text-primary text-uppercase font-weight-bold p-3 fs-1">
      what does WEB3CV look like?
    </h3>
    <div class="row mt-8">
      <div class="col-md-3 min-vh-25">
        <img src="@/assets/img/man-preview.png" class="w-100" alt="avatar" />
        <h4 class="text-primary text-uppercase text-center">Jon Smith</h4>
        <p class="text-center font-weight-light mt-2 text-secondary">
          <i class="fas fa-university"></i>
          University of Birmingham at 2017
        </p>
      </div>
      <div class="col-md-4">
        <h4 class="text-center text-primary text-uppercase fs-4">
          Work experience
        </h4>
        <div class="my-2 px-2">
          <div class="font-weight-bold fs-5">
            Company Name
            <span class="text-success ms-2">
              <i class="fas fa-user-check"></i>
            </span>
          </div>
          <div class="font-weight-light">02.2018 - 03.2020</div>
          <div class="font-weight-bold">
            <span class="text-success me-2">
              <i class="fas fa-check"></i>
            </span>
            Junior game designer
          </div>
          <p class="font-weight-light mt-2">
            This is a short positive review of the company about the employee
          </p>
        </div>
        <hr class="horizontal dark" />
        <div class="my-2 px-2">
          <div class="font-weight-bold fs-5">
            Company Name
            <span class="text-success ms-2">
              <i class="fas fa-user-check"></i>
            </span>
          </div>
          <div class="font-weight-light">05.2020 - 03.2022</div>
          <div class="font-weight-bold">
            <span class="text-success me-2">
              <i class="fas fa-check"></i>
            </span>
            Middle game designer
          </div>
          <p class="font-weight-light mt-2">
            This is a short positive review of the company about the employee
          </p>
        </div>
      </div>
      <div class="col-md-5">
        <h4 class="text-center text-primary text-uppercase fs-4">
          Hard Skills
        </h4>
        <div>
          <Bar
            :data="hardSkillData"
            :options="hardSkillOptions"
            :width="'100%'"
          />
        </div>
      </div>
    </div>
    <h4 class="text-primary text-uppercase fs-4 text-center my-4">
      Soft skills
    </h4>
    <div class="row">
      <div class="col-md-12">
        <PolarArea
          :data="softSkillsData"
          :options="softSkillsOptions"
          :width="'100%'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { PolarArea, Bar } from "vue-chartjs";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export default {
  name: "LookLikePreview",
  components: { PolarArea, Bar },
  props: {},
  data() {
    return {
      softSkillsData: {
        labels: [
          "Autonomy",
          "Resilience in stress",
          "Vitality",
          "Learnability",
          "Conflict",
          "Efficiency",
          "Flexibility",
          "Command",
          "Diligence",
          "Leadership",
          "Risk appetite",
          "Quick Decisions",
          "Processor, routine",
          "Anxiety",
          "Openness",
          "Loyalty",
          "Propensity to lie",
          "Entrepreneurship",
        ],
        datasets: [
          {
            label: "Soft Skills",
            backgroundColor: "rgba(94, 114, 228, .8)",
            borderColor: "rgba(94, 114, 228, .4)",
            pointBackgroundColor: "rgba(94, 114, 228, .6)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(94, 114, 228, .6)",
            data: [
              75, 63, 92, 61, 96, 71, 100, 45, 67, 62, 71, 54, 83, 82, 74, 43,
              92, 58,
            ],
          },
        ],
      },
      softSkillsOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            // display: false,
            position: "left",
          },
        },
      },

      hardSkillData: {
        labels: [
          "Coding ability",
          "Foreign language skills",
          "Typing speed",
          "SEO marketing",
          "Bookkeeping",
          "Computer skills",
          "Proofreading",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#5e72e4",
            data: [12, 20, 12, 87, 10, 40, 39, 100],
          },
        ],
      },
      hardSkillOptions: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
