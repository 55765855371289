import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "./globalScripts";

import "@/assets/scss/material.scss";

import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";

export default {
  install(app) {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
  },
};
