<template>
  <div class="min-vh-50 mt-4 mt-md-0">
    <p class="text-dark text-justify fs-1 font-weight-bold">
      On average, to get a job, an applicant goes through 10 interviews, spends more than 50 hours
    </p>
    <p class="text-dark text-center fs-1 font-weight-bold mt-5">
      We'll fix it!
    </p>
    <p class="text-dark text-center fs-1 font-weight-bold mt-5">
      Get a job right away, save your time!
    </p>
  </div>
</template>

<script>
export default {
  name: "ProblemPreview"
}
</script>

<style scoped>

</style>
