<template>
  <div class="min-vh-70 pt-8">
    <h3 class="text-primary text-uppercase font-weight-bold p-3 fs-1">
      how to verify SOFT skills?
    </h3>
    <div class="row mt-8">
      <div class="col-md-4">
        <img src="@/assets/img/verify/step-3.svg" class="w-100"/>
      </div>
      <div class="col-md-8">
        <p class="text-center mt-4 text-dark font-weight-bold fs-3 my-auto mt-3 mt-md-8">
          Get tested using AI on key soft competencies
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowVerifySoftSkillsPreview",
};
</script>

<style scoped></style>
