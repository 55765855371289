<template>
  <div>
    <NavigationPreview/>
    <div class="container mb-8">
      <div class="min-vh-100">
        <h1 class="text-primary mt-8">Create Your CV</h1>

        <div class="row mt-4">
          <div class="col-md-4">
            <img src="@/assets/img/verify/step-2.svg" class="w-100"/>
          </div>
          <div class="col-md-8">
            <h3 class="text-primary text-center">Connect your socials</h3>
            <div class="text-center">
              <button type="button" class="btn btn-facebook btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-facebook"></i></span>
              </button>
              <button type="button" class="btn btn-twitter btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-twitter"></i></span>
              </button>
              <button type="button" class="btn btn-instagram btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-instagram"></i></span>
              </button>
              <button type="button" class="btn btn-github btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-github"></i></span>
              </button>
              <button type="button" class="btn btn-pinterest btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-pinterest"></i></span>
              </button>
              <button type="button" class="btn btn-youtube btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-youtube"></i></span>
              </button>
              <button type="button" class="btn btn-vimeo btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-vimeo-v"></i></span>
              </button>
              <button type="button" class="btn btn-slack btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-slack"></i></span>
              </button>
              <button type="button" class="btn btn-dribbble btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-dribbble"></i></span>
              </button>
              <button type="button" class="btn btn-reddit btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-reddit"></i></span>
              </button>
              <button type="button" class="btn btn-tumblr btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-tumblr"></i></span>
              </button>
              <button type="button" class="btn btn-linkedin btn-icon-only rounded-circle mx-1">
                <span class="btn-inner--icon"><i class="fab fa-linkedin"></i></span>
              </button>
            </div>

            <hr class="horizontal dark my-4"/>
            <h3 class="text-primary text-center">Talk me about you</h3>
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-dynamic mb-4">
                  <input type="text" class="form-control" placeholder="First Name" aria-label="First Name"
                         aria-describedby="basic-addon1">
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-dynamic mb-4">
                  <input type="text" class="form-control" placeholder="Last Name" aria-label="Last Name"
                         aria-describedby="basic-addon1">
                </div>
              </div>
            </div>

            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Job title" aria-label="Job title"
                     aria-describedby="basic-addon1">
            </div>

            <div class="input-group input-group-dynamic mb-4">
              <textarea class="form-control" placeholder="Summery" row="4"></textarea>
            </div>
          </div>
        </div>
        <hr class="horizontal dark my-4"/>
        <div class="row">
          <div class="col-md-4">
            <span class="text-uppercase text-primary fs-3">
              Work experience
            </span>
          </div>
          <div class="col-md-8">
            <p class="text-secondary text-start">Please talk about you work</p>
            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Job title">
            </div>
            <p class="text-secondary text-start">in</p>
            <div class="row">
              <div class="col-6">
                <div class="input-group input-group-dynamic mb-4">
                  <input type="text" class="form-control" placeholder="Company">
                </div>
              </div>
              <div class="col-6">
                <div class="input-group input-group-dynamic mb-4">
                  <input type="text" class="form-control" placeholder="company.com">
                </div>
              </div>
            </div>
            <div class="input-group input-group-dynamic mb-4">
              <textarea class="form-control" placeholder="About your work" row="4"></textarea>
            </div>
            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Work dates (month.year)">
            </div>
            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Key responsibilities (3)">
            </div>
            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Result">
            </div>
            <div class="text-end">
              <a href="javascript:;" class="btn btn-primary"><i class="fas fa-plus me-1"></i> Add company</a>
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-4"/>
        <div class="row">
          <div class="col-md-4">
            <span class="text-uppercase text-primary fs-3">
              Educations
            </span>
          </div>
          <div class="col-md-8">
            <div class="input-group input-group-dynamic mb-4">
              <input type="text" class="form-control" placeholder="Name of the university, specialty, years of study">
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-4"/>
        <div class="row">
          <div class="col-md-4">
            <span class="text-uppercase text-primary fs-3">
              Hard Skills
            </span>
          </div>
          <div class="col-md-8">
            <div class="input-group input-group-dynamic mb-4">
              <input class="form-control" id="choices-skills" type="text" value="vuejs, angular, react" placeholder="Enter your hard skills" />
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-4"/>
        <div class="row">
          <div class="col-md-4">
            <span class="text-uppercase text-primary fs-3">
              Soft Skills
            </span>
          </div>
          <div class="col-md-8">
            <div class="input-group input-group-dynamic mb-4">
              <input class="form-control" id="choices-soft-skills" type="text" value="vuejs, angular, react" placeholder="Enter your soft skills" />
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-4"/>
        <div class="text-center">
          <a href="javascript:;" class="btn btn-primary btn-lg fs-3">Create CV</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavigationPreview from "@/components/landing-fragments/NavigationPreview";

export default {
  name: "CreateCV",
  components: {
    NavigationPreview,
  },
};
</script>

<style scoped></style>
