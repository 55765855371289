<template>
  <div class="min-vh-70 pt-8">
    <h3 class="text-primary text-uppercase font-weight-bold p-3 fs-1">
      HOW TO VERIFY HARD SKILLS?
    </h3>
    <div class="row mt-8">
      <div class="col-md-4">
        <img src="@/assets/img/verify/step-1.svg" class="w-100"/>
      </div>
      <div class="col-md-8">
        <ul class="text-dark font-weight-bold fs-md-1 fs-3 text-start">
          <li>Upload your best code, project or test</li>
          <li>Get a feedback from colleagues in your specialty</li>
          <li>Get a rating from at least three experts</li>
          <li>Find out your level</li>
        </ul>
        <p class="text-center mt-4 text-dark font-weight-bold fs-3">
          Junior/Middle/Senior
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowVerifyHardSkillsPreview",
};
</script>

<style scoped></style>
