<template>
  <div class="min-vh-100 pt-8 bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p class="fs-1 text-light text-uppercase">
            CONTACT INFORMATION
          </p>
        </div>
        <div class="col-md-6 text-center">
          <router-link to="/create" class="btn btn-lg fs-1 btn-light text-primary text-uppercase">
            Apply
          </router-link>
          <img src="@/assets/img/contact.svg" class="w-100" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ContactsPreview"
}
</script>

<style scoped>

</style>
