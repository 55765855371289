<template>
  <div class="min-vh-100 pt-8">
    <div class="text-center">
      <h3
        class="text-primary text-uppercase font-weight-bold d-inline p-3 fs-1 border border-primary border-radius-2xl">
        Plans
      </h3>
    </div>
    <div class="row mt-8">
      <div class="col-md-4">
        <a href="javascript:;"  class="d-block border border-radius-2xl border-primary p-2 p-md-4 h-100">
          <img src="@/assets/img/plans/free.svg" class="w-100" />
          <h4 class="text-primary font-weight-bold fs-1 text-center mt-5">Full Pack</h4>
          <p class="text-primary font-weight-light fs-2 text-uppercase text-center">Free/ 1 Year</p>
        </a>
      </div>
      <div class="col-md-4">
        <a href="javascript:;"  class="d-block border border-radius-2xl border-primary p-2 p-md-4 h-100">
          <img src="@/assets/img/plans/basic.svg" class="w-100" />
          <h4 class="text-primary font-weight-bold fs-1 text-center">Basic</h4>
          <p class="text-primary font-weight-light fs-2 text-uppercase text-center">$9/ 1 Year</p>
        </a>
      </div>
      <div class="col-md-4">
        <a href="javascript:;" class="d-block border border-radius-2xl border-primary p-2 p-md-4 h-100">
          <img src="@/assets/img/plans/pro.svg" class="w-100" />
          <h4 class="text-primary font-weight-bold fs-1 text-center">Pro</h4>
          <p class="text-primary font-weight-light fs-2 text-uppercase text-center">$20/ 1 Year</p>
          <ul class="text-primary">
            <li>Add a bullet point</li>
            <li>Add as many as needed</li>
            <li>Add a bullet point</li>
            <li>Add as many as needed</li>
          </ul>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlansPreview"
}
</script>

<style scoped>

</style>
