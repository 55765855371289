import LandingView from "@/views/LandingView";
import { createRouter, createWebHashHistory } from "vue-router";
import CreateCV from "@/views/CreateCV";

const routes = [
  {
    path: "/",
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "LandingView",
        components: { default: LandingView },
      },
      {
        path: "/create",
        name: "CreateCV",
        components: { default: CreateCV },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
