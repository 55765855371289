<template>
  <div class="min-vh-100 pt-10 pt-md-8">
    <div class="row">
      <div class="col-md-6">
        <h1 class="text-dark fs-4 font-weight-light">Web3CV</h1>
        <p class="fs-2 text-dark font-weight-bold text-uppercase">
          provably fair CV to EMPOWER your hiring experience
        </p>

        <router-link to="/create" class="btn btn-lg btn-primary text-uppercase">Apply</router-link>
      </div>
      <div class="col-md-6">
        <div class="preview-cloud bg-gray-300 border border-radius-2xl border-primary p-3 p-md-5 mb-3">
          <p class="text-start text-dark font-weight-bold fs-5">
            Hello! I'm Ann, Head of Recruiting
          </p>
          <p class="text-end text-primary font-weight-bold fs-5">
            Hello! Nice to meet you !
          </p>
          <p class="text-start text-dark font-weight-bold fs-5">
            Before we start It might be better to know that you have web3 CV
          </p>
          <p class="text-end text-primary font-weight-bold fs-5">
            Yes, share! Here is the link _______!
          </p>
          <p class="text-start text-dark font-weight-bold fs-5">
            Wow! Let’s jump to the next step then :)
          </p>
        </div>
        <div class="mx-auto">
          <img src="@/assets/img/preview.svg" class="w-100" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyPreview",
};
</script>

<style scoped></style>
