<template>
  <div>
    <NavigationPreview />
    <div class="container container-fluid">
      <CompanyPreview />
      <ProblemPreview />
      <PartnersPreview />
      <HowItsWorkPreview />
      <HowVerifyHardSkillsPreview />
      <HowVerifySoftSkillsPreview />
      <HowVerifyWorkingExperiencePreview />
      <LookLikePreview />
      <RecruterContactPreview />
      <WhyINeedThisPreview />
      <PlansPreview />
    </div>
    <ContactsPreview />
  </div>
</template>

<script>
import CompanyPreview from "@/components/landing-fragments/CompanyPreview";
import PartnersPreview from "@/components/landing-fragments/PartnersPreview";
import HowItsWorkPreview from "@/components/landing-fragments/HowItsWorkPreview";
import HowVerifyHardSkillsPreview from "@/components/landing-fragments/HowVerifyHardSkillsPreview";
import WhyINeedThisPreview from "@/components/landing-fragments/WhyINeedThisPreview";
import PlansPreview from "@/components/landing-fragments/PlansPreview";
import ContactsPreview from "@/components/landing-fragments/ContactsPreview";
import NavigationPreview from "@/components/landing-fragments/NavigationPreview";
import ProblemPreview from "@/components/landing-fragments/ProblemPreview";
import HowVerifySoftSkillsPreview from "@/components/landing-fragments/HowVerifySoftSkillsPreview";
import HowVerifyWorkingExperiencePreview from "@/components/landing-fragments/HowVerifyWorkingExperiencePreview";
import LookLikePreview from "@/components/landing-fragments/LookLikePreview";
import RecruterContactPreview from "@/components/landing-fragments/RecruterContactPreview";

export default {
  name: "LandingView",
  components: {
    RecruterContactPreview,
    LookLikePreview,
    ProblemPreview,
    NavigationPreview,
    ContactsPreview,
    PlansPreview,
    WhyINeedThisPreview,
    HowVerifyHardSkillsPreview,
    HowVerifySoftSkillsPreview,
    HowVerifyWorkingExperiencePreview,
    HowItsWorkPreview,
    PartnersPreview,
    CompanyPreview,
  },
  data() {
    return {
      title: "",
    };
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped></style>
