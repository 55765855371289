<template>
  <div class="min-vh-50 mt-8">
    <div class="text-uppercase text-primary text-center fs-1">
      if you do not agree with an assessment, you can always contact our web3
      recruiter
    </div>

    <div class="row mt-8">
      <div class="col-md-2 offset-md-4 text-center">
        <img src="@/assets/img/recruter.jpg" class="avatar w-100 h-auto" />
      </div>
      <div class="col-md-3 text-center">
        <router-link
          to="/create"
          class="btn btn-lg fs-1 btn-primary text-uppercase mt-4"
        >
          Apply
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecruterContactPreview",
};
</script>

<style scoped></style>
