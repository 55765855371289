<template>
  <div class="min-vh-50 mt-4 mt-md-0">
    <div class="row">
      <div class="col-md-4 offset-md-2 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/binance.png"/>
      </div>
      <div class="col-md-4 offset-md-1 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/metamask.png"/>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-4 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/opensea.png"/>
      </div>
      <div class="col-md-4 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/1inch.png"/>
      </div>
      <div class="col-md-4 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/polkadot.png"/>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-4 text-center mt-4 mt-md-0">
        <img class="w-50" src="@/assets/img/companies/kusama.png"/>
      </div>
    </div>

    <div class="text-primary mt-4 fs-2 text-uppercase font-weight-bold text-center text-md-end mt-3 mt-md-0">
      WOULD LOVE TO HIRE YOU ASAP...
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersPreview"
}
</script>

<style scoped>

</style>
