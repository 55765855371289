<template>
  <div class="min-vh-70 pt-8">
    <div class="text-center">
      <h3
        class="text-primary text-uppercase font-weight-bold d-inline p-3 fs-1 border border-primary border-radius-2xl"
      >
        WHY I NEED THIS?
      </h3>
    </div>
    <div class="row mt-8">
      <div class="col-md-8">
        <ol class="text-primary fs-2">
          <li>Remove generic interview questions</li>
          <li>Get an offer 3 times faster</li>
          <li>New web3 market standard</li>
        </ol>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/man-preview.png" class="w-100" alt="avatar" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyINeedThisPreview",
};
</script>

<style scoped></style>
