<template>
  <nav
    class="navbar navbar-expand-lg position-fixed position-static top-0 w-100 navbar-light bg-light z-index-3 py-3">
    <div class="container">
      <div class="text-center d-block w-100 w-md-auto mb-2 mb-md-0">
        <router-link class="navbar-brand text-primary" to="/">
          <h2 class="font-weight-bold fs-5 text-primary ms-auto d-inline">
            Web3CV
          </h2>
        </router-link>
      </div>
      <div class="col-auto text-center px-3 py-2">
        <!--        <a class="btn btn-primary text-dark">Узнай за акцию</a>-->
      </div>

      <div class="text-center w-100 w-md-auto" id="navigation">
        <div class="navbar-nav navbar-nav-hover ms-auto text-primary h4">
          <router-link to="/create" class="btn btn-primary fs-6 d-block d-lg-auto px-0 px-md-4">Apply</router-link>
        </div>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationPreview"
}
</script>

<style scoped>

</style>
