<template>
  <div class="min-vh-100 pt-8">
    <div class="text-center">
      <h3
        class="text-primary text-uppercase font-weight-bold d-inline p-3 fs-1 border border-primary border-radius-2xl">
        How Its work?
      </h3>
    </div>

    <div class="row mt-8">
      <div class="col-md-4 text-center">
        <img src="@/assets/img/verify/step-2.svg" class="w-70" />
      </div>
      <div class="col-md-8">
        <ol class="text-primary font-weight-bold fs-md-1 fs-1">
          <li>Upload CV or send us the link</li>
          <li>Approve working experience</li>
          <li>Get your Web3CV in a sec</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItsWorkPreview"
}
</script>

<style scoped>

</style>
